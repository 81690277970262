<template>
  <base-empty-block-page
    title="Страница не доступна"
  />
</template>

<script>

  export default {
    name: 'AccessDenied403',
    computed: {

    },
  }
</script>

<style lang="scss" scoped>
</style>
